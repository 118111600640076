import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './aboutus.css'
import Stack from 'react-bootstrap/Stack'
import Button from 'react-bootstrap/Button'

import projectfinish from '../../assets/images/news paper (1).png'
import yeasexoeri from '../../assets/images/Calender (1).png'
import engineer from '../../assets/images/Engineer (1).png'
import client from '../../assets/images/clients (1).png'
import { Col, Container, Row, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';

import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';

const LandingAboutus = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const [mref, minView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const projectcountAnimation = useSpring({
    number: inView ? 120 : 0,
    from: { number: 0 },
    config: {
        duration: 3000,
        easing: t => 1 - Math.pow(1 - t, 3) 
    }
});
  const yearscountAnimation = useSpring({
    number: inView ? 5 : 0,
    from: { number: 0 },
    config: {
        duration: 3000,
        easing: t => 1 - Math.pow(1 - t, 3) 
    }
});
  const expertscountAnimation = useSpring({
    number: inView ? 50 : 0,
    from: { number: 0 },
    config: {
        duration: 3000,
        easing: t => 1 - Math.pow(1 - t, 3) 
    }
});
  const satisfiedcountAnimation = useSpring({
    number: inView ? 200 : 0,
    from: { number: 0 },
    config: {
        duration: 3000,
        easing: t => 1 - Math.pow(1 - t, 3) 
    }
});
//mobile view
  const mprojectcountAnimation = useSpring({
    number: minView ? 120 : 0,
    from: { number: 0 },
    config: {
        duration: 3000,
        easing: t => 1 - Math.pow(1 - t, 3) 
    }
});
  const myearscountAnimation = useSpring({
    number: minView ? 5 : 0,
    from: { number: 0 },
    config: {
        duration: 3000,
        easing: t => 1 - Math.pow(1 - t, 3) 
    }
});
  const mexpertscountAnimation = useSpring({
    number: minView ? 50 : 0,
    from: { number: 0 },
    config: {
        duration: 3000,
        easing: t => 1 - Math.pow(1 - t, 3) 
    }
});
  const msatisfiedcountAnimation = useSpring({
    number: minView ? 200 : 0,
    from: { number: 0 },
    config: {
        duration: 3000,
        easing: t => 1 - Math.pow(1 - t, 3) 
    }
});
  return (
    <div>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <section className='w-100 border border-5 bg  px-5 rounded-5 '>
          <div className="container mx-5">
            <div className="row my-5  bg-white rounded-3 ">
              <div className="col-lg-6 col-md-6 col-12 l-bg rounded-3 ">
                <Stack gap={3} className='ms-3 text-start py-5 '>
                  <div className="p-2 head-font">About Us</div>
                  <div className="p-2 headings">Welcome to Autotech Industries</div>
                  <div className="p-2 para-font">At Autotech Industries, we are a dynamic and innovative company specializing in a wide range of services to meet the needs of industrial and civil construction projects. With a proven track record in industrial and civil construction, painting work, fabrication, B2B procurement of industrial supplies, packaging, and more, we are your trusted partner for all your project needs.</div>
                  <Container>
                    <Link to='/contact-us'>
                      <Button variant="" size="" href='/contact-us' className='btn-align-left border text-white fw-bold hov float-end'>
                        Request Enquiry
                      </Button>
                    </Link>

                  </Container>
                </Stack>
              </div>
              <div className="col-lg-6 col-md-6 col-12 d-flex align-items-center">
                <Container className=' '>
                  <Row className=''>
                    <Col sm={12} md={6} className='align-content-center  '>
                      <Stack direction="horizontal" className='my-3' ref={ref}>
                        <Image src={projectfinish} rounded width={120} />
                        <Stack>
                          <animated.span className='main-num' >{projectcountAnimation.number.to(val => `${Math.floor(val)}`)}</animated.span>
                          {/* <span className='main-num'>120</span> */}
                          <span className='main-text'>Projects Delivered Successfully</span>
                        </Stack>
                      </Stack>
                    </Col>
                    <Col>
                      <Stack direction="horizontal" className='my-3'>
                        <Image src={yeasexoeri} rounded width={100} />
                        <Stack>
                        <animated.span className='main-num' >{yearscountAnimation.number.to(val => `${Math.floor(val)}+`)}</animated.span>
                          {/* <span className='main-num'>5+</span> */}
                          <span className='main-text'>Year’s of Experience</span>
                        </Stack>
                      </Stack>
                    </Col>
                  </Row>
                  <Row className=''>
                    <Col sm={12} md={6} className='align-content-center  '>
                      <Stack direction="horizontal" className='my-3'>
                        <Image src={engineer} rounded width={100} />
                        <Stack>
                        <animated.span className='main-num' >{expertscountAnimation.number.to(val => `${Math.floor(val)}+`)}</animated.span>
                          {/* <span className='main-num'>50+</span> */}
                          <span className='main-text'>Experts Employed</span>
                        </Stack>
                      </Stack>
                    </Col>
                    <Col>
                      <Stack direction="horizontal" className='my-3 d-flex justify-content-around '>
                        <Image src={client} rounded width={100} />
                        <Stack>
                        <animated.span className='main-num' >{satisfiedcountAnimation.number.to(val => `${Math.floor(val)}+`)}</animated.span>
                          {/* <span className='main-num'>200+</span> */}
                          <span className='main-text'>Satisfied Clients</span>
                        </Stack>
                      </Stack>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </section>
      </Box>


      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <section className=' '>
          <div className="container">
            <div className="row my-5  bg-white rounded-3 ">
              <div className="col-lg-6 col-md-6 col-12 l-bg rounded-3 ">
                <Stack gap={1} className='ms-3 text-start py-5 '>
                  <div className="p-2 head-font">About Us</div>
                  <div className="p-2 headings">Welcome to Autotech Industries</div>
                  <div className="p-2 para-font">At Autotech Industries, we are a dynamic and innovative company specializing in a wide range of services to meet the needs of industrial and civil construction projects. With a proven track record in industrial and civil construction, painting work, fabrication, B2B procurement of industrial supplies, packaging, and more, we are your trusted partner for all your project needs.</div>
                  <Container>
                    <Link to='/contact-us'>
                      <Button variant="" size="" href='/contact-us' className='btn-align-left border text-white fw-bold hov float-end'>
                        Request Enquiry
                      </Button>
                    </Link>
                  </Container>
                </Stack>
              </div>
              <div className="col-lg-6 col-md-6 col-12 mt-5 d-flex justify-content-center">
                <Container className=' '>
                  <div className='text-center'>
                    <Stack direction="horizontal" className='' ref={mref}>
                      <Image src={projectfinish} rounded width={120} />
                      <Stack>
                      <animated.span className='main-num' >{mprojectcountAnimation.number.to(val => `${Math.floor(val)}`)}</animated.span>
                        {/* <span className='main-num'>120</span> */}
                        <span className='main-text'>Projects Delivered Successfully</span>
                      </Stack>
                    </Stack>
                  </div>
                  <div className='text-center'>
                  <Stack direction="horizontal" className='my-3' ref={mref}>
                    <Image src={yeasexoeri} rounded width={100} />
                    <Stack>
                    <animated.span className='main-num' >{myearscountAnimation.number.to(val => `${Math.floor(val)}+`)}</animated.span>
                      {/* <span className='main-num'>5+</span> */}
                      <span className='main-text'>Year’s of Experience</span>
                    </Stack>
                  </Stack>
                  </div>
                  <div className='text-center'>
                  <Stack direction="horizontal" className='my-3' ref={mref}>
                    <Image src={engineer} rounded width={100} />
                    <Stack>
                    <animated.span className='main-num' >{mexpertscountAnimation.number.to(val => `${Math.floor(val)}+`)}</animated.span>
                      {/* <span className='main-num'>50+</span> */}
                      <span className='main-text'>Experts Employed</span>
                    </Stack>
                  </Stack>
                  </div>
                  <div className='text-center'>
                  <Stack direction="horizontal" className='my-3 d-flex justify-content-around ' ref={mref}>
                    <Image src={client} rounded width={100} />
                    <Stack>
                    <animated.span className='main-num' >{msatisfiedcountAnimation.number.to(val => `${Math.floor(val)}+`)}</animated.span>
                      {/* <span className='main-num'>200+</span> */}
                      <span className='main-text'>Satisfied Clients</span>
                    </Stack>
                  </Stack>
                  </div>
                </Container>
              </div>
            </div>
          </div>
        </section>
      </Box>
    </div>
  )
}

export default LandingAboutus
