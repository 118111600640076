import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Herosec from '../../section/verticals/construction'
import Formcontact from '../../section/contactform/form'

import safteysecurity from '../../assets/images/Safety_Equipments_893x552.webp'

import Cards from '../../section/cards'

import PPESecurity from '../../assets/images/PPE Security.jpeg'
import FallProtection from '../../assets/images/Fall Protection.jpeg'
import FireProtection from '../../assets/images/Fire Protection.jpeg'
import Respirators from '../../assets/images/respirator_square.jpeg'
import Filters from '../../assets/images/filter_square.jpeg'


import mapimg from '../../assets/images/map.png'

const Index = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);
    const link = '/contact-us'
    return (
        <div>
            <Herosec
                menuText='Safety & Security'
                leftText='
                Our Safety and Security division offers premium industrial safety equipment and advanced security solutions, ensuring a safe, secure working environment, and protecting your operations with reliable, top-tier products tailored to your needs.'
                rightimg={safteysecurity}
                link = {link}
            />

            <div className='container my-5'>
                <div className='d-flex flex-wrap gap-2 justify-content-around '>
                    <Cards img={PPESecurity}  text="PPE Security" />
                    <Cards img={FallProtection} text="Fall Protection" />
                    <Cards img={FireProtection} text="Fire Protection" />
                </div>
            </div>
            <div className='container my-5'>
                <div className='d-flex flex-wrap gap-2 justify-content-evenly     '>
                    <Cards img={Respirators}  text="Respirators" />
                    <Cards img={Filters} text="Filters" />
                </div>
            </div>
            

            <Container className=' mt-4'>
                <span className='form-head'>Get in Touch</span>
            </Container>

            <div>
                <Container className=' mb-5 pb-5' >
                    <Row>
                        <Col sm={12} lg={6} className='my-auto'>
                            <img src={mapimg} alt="map" className='img-fluid ' />
                        </Col>
                        <Col sm={12} md={6} className='form-bg p-4 rounded-4 '>
                            <Formcontact />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Index
