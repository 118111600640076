import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Herosec from '../../section/verticals/construction'
import Formcontact from '../../section/contactform/form'
import Cards from '../../section/cards'

import instrumrntimg from '../../assets/images/Instrument_Items_893x552.webp'

import flowmeter from '../../assets/images/flowmeter_square.jpeg'
import rotameter from '../../assets/images/rotameter_450x450.jpeg'
import transmitter from '../../assets/images/transmitter_450x450.jpeg'
import pressuregauge from '../../assets/images/pressuregauge_450x450.jpeg'
import controlvalves from '../../assets/images/control_valves_square.jpeg'
import switches from '../../assets/images/switchesandterminal_450x450.jpeg'
import mapimg from '../../assets/images/map.png'
const Index = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Herosec
                menuText='Instrument Items'
                leftText='Our Instrument Items division offers top-tier industrial instruments, delivering precise measurements and robust performance to meet the rigorous demands of your operations, ensuring accuracy and reliability.'
                rightimg={instrumrntimg}
                link='/contact-us'
            />

            <div className='container my-5'>
                <div className='d-flex flex-wrap gap-2 justify-content-around '>
                    <Cards img={flowmeter} text="Flowmeter" />
                    <Cards img={rotameter} text="Rotameter" />
                    <Cards img={transmitter} text="Transmitter" />
                </div>
            </div>
            <div className='container my-5'>
                <div className='d-flex flex-wrap gap-2 justify-content-around '>
                    <Cards img={pressuregauge} text="Pressure Gauge" />
                    <Cards img={controlvalves} text="Control Valves" />
                    <Cards img={switches} text="Switches and Terminals" />
                </div>
            </div>

            <Container className=' mt-4'>
                <span className='form-head'>Get in Touch</span>
            </Container>

            <div>
                <Container className=' mb-5 pb-5' >
                    <Row>
                        <Col sm={12} lg={6} className='my-auto'>
                            <img src={mapimg} alt="map" className='img-fluid ' />
                        </Col>
                        <Col sm={12} md={6} className='form-bg p-4 rounded-4 '>
                            <Formcontact />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Index
