import React, { useEffect } from 'react'
import { Col, Container, Row, ListGroup, Card } from 'react-bootstrap'
import aboutimg from '../../assets/images/aboutus.jpeg'
import missionimmg from '../../assets/images/mission.jpeg'
import visionimg from '../../assets/images/vision.jpeg'
import './index.css'

const Index = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Container>
                <Row className=' my-2 my-lg-4 aboutus rounded-4 py-2' >
                    <Col sm={12} md={6} className=' text-center text-lg-end my-auto'>
                        <img src={aboutimg} alt="logo" className='img-fluid rounded-4 hov' />
                    </Col>
                    <Col sm={12} md={6} className='my-auto text-lg-start'>
                        <div className='hov'>
                            <h1 className='head-text'>About Us</h1>
                            <p>
                                Auto Tech Industries has been a cornerstone in the construction and industrial supply sectors for over five years. Specializing in a diverse range of services, we cater to both industrial and residential needs with a commitment to quality and innovation. Our expertise spans intricate machine foundations, foundation pits, Trimix flooring, and dock work. We excel in Pre-Engineered Buildings (PEB), trolley making, rolling shutters, false ceilings, and aluminum and glass offices and partitions. Our fabrication and aluminum and glass work showcase our craftsmanship and attention to detail. We also offer packaging and industrial supply of B2B materials, ensuring clients receive tailored solutions. At Auto Tech Industries, we pride ourselves on delivering excellence through meticulous planning, execution, and a client-centric approach.

                            </p>
                        </div>
                    </Col>
                </Row>

                

                <Row className=' my-5 aboutus rounded-4' >
                    
                    <Col sm={12} md={6} className='my-auto text-lg-end'>
                        <div className='hov'>
                            <h1 className='head-text'>Vision</h1>
                            <p className=''>
                                To be the leading provider of innovative construction and industrial solutions, transforming spaces with quality craftsmanship and sustainable practices, while consistently exceeding client expectations and setting new standards in the industry.
                            </p>
                        </div>
                    </Col>
                    <Col sm={12} md={6} className=' text-center text-lg-end my-auto'>
                        <img src={visionimg} alt="logo" className='img-fluid rounded-4 hov' />
                    </Col>
                </Row>

                <Row className=' my-5 aboutus rounded-4' >
                <Col sm={12} md={6} className=' text-center text-lg-end my-auto '>
                        <img src={missionimmg} alt="logo" className='img-fluid rounded-4 hov' />
                    </Col>
                    <Col sm={12} md={6} className='my-auto text-lg-start'>
                        <div className='hov'>
                            <h1 className='head-text text-lg-start'>Mission</h1>
                            <p>
                                <strong>Quality Excellence:</strong> Deliver superior construction and industrial solutions through the highest standards of quality and safety.<br/>
                                <strong>Innovation and Technology:</strong> Integrate advanced technologies and innovative practices to enhance efficiency and project outcomes.<br/>
                                <strong>Customer Satisfaction:</strong> Foster strong, lasting relationships with clients by understanding their needs and providing tailored, reliable solutions.<br/>
                                <strong>Sustainability:</strong> Commit to sustainable practices that minimize environmental impact and promote long-term growth and viability.<br/>
                                <strong>Employee Development:</strong> Invest in our team’s growth and development, empowering them to achieve professional excellence and contribute to our success.


                            </p>
                        </div>
                    </Col>
                    
                </Row>
            </Container>
        </div>
    )
}

export default Index
