import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Herosec from '../../section/verticals/construction'
import Formcontact from '../../section/contactform/form'
import mechanicalitemimg from '../../assets/images/Mechanical_Items_893x552.webp'
import Cards from '../../section/cards'
import pipes from '../../assets/images/pipefitting_square.jpeg'
import valves from '../../assets/images/valves_square.jpeg'
import rods from '../../assets/images/platesrod_square.jpeg'
import machinespare from '../../assets/images/machinespare_450x450.jpeg'
import bearing from '../../assets/images/bearing_square.jpeg'
import hoses from '../../assets/images/hoses_450x450.jpeg'
import mapimg from '../../assets/images/map.png'

const Index = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Herosec
                menuText='Mechanical Items'
                leftText='Our Mechanical Items division specializes in providing high-quality industrial mechanical supplies, ensuring reliability and performance for your operations with a focus on precision, durability, and competitive pricing.'
                rightimg={mechanicalitemimg}
                link='/contact-us'
            />

            <div className='container my-5'>
                <div className='d-flex flex-wrap gap-2 justify-content-around '>
                    <Cards img={pipes}  text="Pipes and Fitting" />
                    <Cards img={valves} text="Valves" />
                    <Cards img={rods} text="Plates and Rods" />
                </div>
            </div>
            <div className='container my-5'>
                <div className='d-flex flex-wrap gap-2 justify-content-around '>
                    <Cards img={machinespare}  text="Machine Spares" />
                    <Cards img={bearing} text="Bearings" />
                    <Cards img={hoses} text="Hoses" />
                </div>
            </div>

            <Container className=' mt-4'>
                <span className='form-head'>Get in Touch</span>
            </Container>

            <div>
                <Container className=' mb-5 pb-5' >
                    <Row>
                        <Col sm={12} lg={6} className='my-auto'>
                            <img src={mapimg} alt="map" className='img-fluid ' />
                        </Col>
                        <Col sm={12} md={6} className='form-bg p-4 rounded-4 '>
                            <Formcontact />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Index
