
import './App.css';
import React, { useState, useEffect } from 'react'
import { HashRouter as Router } from 'react-router-dom';
import Header from './components/header';
import MainRoutes from './routes';
import Footer from './components/footer';
import Splashscreen from '../src/components/splashscreen';


function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Set a timer to hide the splash screen after 3 seconds (adjust as needed)
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 5000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  // if (isLoading) {
  //   return <Splashscreen />;
  // }
  return (
    <Router>
      <div>
        <Header />
        <MainRoutes />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
