import React, { useEffect } from 'react'
import Packaging from '../../section/verticals/construction'

import silicagel from '../../assets/images/Silica_Gel_1_893x552.webp'
import stretchfilm from '../../assets/images/Stretch_Film_893x552.webp'
import edgeboard from '../../assets/images/Edge_Boards_893x552.webp'
import bopptape from '../../assets/images/Bopp_Tape_893x552.webp'
import woodenpallet from '../../assets/images/stack-of-wooden-pallets_893x552.webp'

const link = '/contact-us'
const Index = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
}, []);
  return (
    <div>
      <Packaging 
        menuText='Angle Board / Edge Board'
        leftText='Protect your shipments with our Angle Boards/Edge Boards. Our durable, high-quality boards prevent damage, ensuring safe transit. Ideal for securing pallets and protecting edges, providing reliable packaging solutions for all industries.'
        rightimg={edgeboard}
        link={link}
      />
      <Packaging 
        menuText='Stretch Film'
        leftText='Secure your products with our premium Stretch Films. Offering superior strength and cling, our films protect against dust and moisture, ensuring stable, damage-free shipments. Ideal for all your packaging needs.'
        rightimg={stretchfilm}
        link={link}
      />
      <Packaging 
        menuText='Silica Gel'
        leftText='Keep your products dry with our Silica Gel packs. Our desiccants efficiently absorb moisture, preventing mold and corrosion. Ideal for electronics, pharmaceuticals, and food, ensuring long-lasting freshness and protection.'
        rightimg={silicagel}
        link={link}
      />
      <Packaging 
        menuText='Bopp Tape'
        leftText='Seal your packages with our high-quality BOPP Tape. Strong and durable, it provides excellent adhesion and clarity. Ideal for securing boxes and parcels, ensuring reliable and tamper-proof packaging.'
        rightimg={bopptape}
        link={link}
      />
      <Packaging 
        menuText='Wooden Pallet'
        leftText="Transport your goods securely with our Wooden Pallets. Crafted from sturdy wood, our pallets ensure safe handling and storage, ideal for warehouses and shipping. Reliable support for your products' journey."
        rightimg={woodenpallet}
        link={link}
      />
    </div>
  )
}

export default Index
