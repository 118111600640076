import React from 'react'
import { Card } from 'react-bootstrap'
import './index.css'
const index = (props) => {
    return (
        <div>
            <Card className='card' style={{ width: '18rem', border: '1px solid #F9B4C2'}}>
                <Card.Img className='card-img' variant="top" src={props.img} />
                <Card.Body className='card-body-bg'>
                    <Card.Title>{props.title}</Card.Title>
                    <Card.Text className='text text-center text-white' >
                        {props.text}
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    )
}

export default index
