import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom';
import Aluminium from '../../section/verticals/construction'

import partition from '../../assets/images/partition_893x552.jpeg'
import sliding from '../../assets/images/slidingwindow_893x552.jpeg'
import falseceiling from '../../assets/images/false_3_893x552.jpeg'
import curtainwindow from '../../assets/images/curtainwindow_893x552.jpeg'

const Index = () => {

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);

    const section1Ref = useRef(null);
    const section2Ref = useRef(null);
    const section3Ref = useRef(null);
    const section4Ref = useRef(null);

    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;
        console.log(hash);
        if (hash === '#section1' && section1Ref.current) {
            section1Ref.current.scrollIntoView({ behavior: 'smooth' });
        } else if (hash === '#section2' && section2Ref.current) {
            section2Ref.current.scrollIntoView({ behavior: 'smooth' });
        } else if (hash === '#section3' && section3Ref.current) {
            section3Ref.current.scrollIntoView({ behavior: 'smooth' });
        }
        else if (hash === '#section4' && section4Ref.current) {
            section4Ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location]);

    // const location = useLocation();
    const links = '/contact-us'
    return (
        <div >
            <Aluminium ref={section1Ref}
                menuText='Aluminium Partition & Glass Work'
                leftText="
                        Enhance your spaces with our Aluminium Partition & Glass Work solutions. Custom sleek designs ensure privacy and an open feel, tailored for durability and style. Ideal for offices, homes, and commercial spaces."
                rightimg={partition}
                link={links}
            />
            <Aluminium ref={section2Ref}
                menuText='Aluminium Sliding Window Work'
                leftText="     
                        Upgrade your space with sleek, durable, and energy-efficient aluminium sliding windows. Enjoy smooth operation, enhanced natural light, and superior security. Custom solutions and seamless installation ensure perfect fit and quality."
                rightimg={sliding}
                link={links}
            />
            <Aluminium ref={section3Ref}
                menuText='False Ceiling Work'
                leftText="
                        Upgrade your space with our sleek and durable aluminium sliding windows. Enjoy smooth operation, enhanced natural light, and energy efficiency. Custom solutions and seamless installation ensure elegance and security for any setting."
                rightimg={falseceiling}
                link={links}
            />
            <Aluminium ref={section4Ref}
                menuText='Window Curtain Work'
                leftText="
                        Elevate your windows with our exquisite curtains, blending style and functionality. From classic to contemporary designs, we offer elegant solutions for homes, offices, and commercial settings with a wide range of fabrics."
                rightimg={curtainwindow}
                link={links}
            />
        </div>
    )
}

export default Index
