import React, {useEffect} from 'react'

import Herosec from '../../section/verticals/construction'
import mechanicalitemimg from '../../assets/images/Mechanical_Items_893x552.webp'
import instrumrntimg from '../../assets/images/Instrument_Items_893x552.webp'
import electricalimg from '../../assets/images/Electrical_Items_893x552.webp'
import abrasiveimg from '../../assets/images/Abrasives_and_Tools_893x552.webp'
import hydraulicimg from '../../assets/images/Hydraulics_and_Pneumatics_893x552.webp'
import ithardwareimg from '../../assets/images/IT_Hardware_893x552.webp'
import officefurniture from '../../assets/images/Office_Furniture_893x552.webp'
import safteysecurity from '../../assets/images/Safety_Equipments_893x552.webp'


const Index = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Herosec
                menuText='Mechanical Items'
                leftText='Our Mechanical Items division specializes in providing high-quality industrial mechanical supplies, ensuring reliability and performance for your operations with a focus on precision, durability, and competitive pricing.'
                rightimg={mechanicalitemimg}
                link='/mechanical-item'
            />

            <Herosec
                menuText='Instrument Items'
                leftText='Our Instrument Items division offers top-tier industrial instruments, delivering precise measurements and robust performance to meet the rigorous demands of your operations, ensuring accuracy and reliability.'
                rightimg={instrumrntimg}
                link='/instrument-item'
            />

            <Herosec
                menuText='Electrical Items'
                leftText='Our Electrical Items division supplies premium industrial electrical components, ensuring safety, efficiency, and reliability to power your operations with cutting-edge technology and competitive pricing.'
                rightimg={electricalimg}
                link='/electrical-item'
            />

            <Herosec
                menuText='Abrasive and Tools'
                leftText='Our Abrasives and Tools division offers top-quality, durable, and precise industrial tools and materials, enhancing productivity and performance. Trust us for reliable cutting, grinding, and polishing solutions tailored to your needs.'
                rightimg={abrasiveimg}
                link='/abrasive-tools'
            />

            <Herosec
                menuText='Hydraulics and Pneumatics'
                leftText='Our Hydraulics & Pneumatics division delivers high-performance industrial solutions with reliable and efficient components, optimizing your machinery and processes for enhanced productivity and operational efficiency. Trust us for superior hydraulic and pneumatic systems.'
                rightimg={hydraulicimg}
                link='/hydraulics-pneumatics'
            />

            <Herosec
                menuText='IT Hardware and Appliances'
                leftText='Our IT Hardware and Appliances division delivers advanced technology solutions, offering reliable and efficient IT hardware and appliances to support and enhance your business operations. Trust us for superior IT hardware and appliances.'
                rightimg={ithardwareimg}
                link='/it-hardware-appliance'
            />

            <Herosec
                menuText='Office Furniture and Stationary'
                leftText='
                Our Office Furniture & Stationery division provides high-quality office furnishings and essential stationery, meticulously designed to enhance workplace efficiency, comfort, and productivity, ensuring a well-equipped and productive work environment.'
                rightimg={officefurniture}
                link='/Office-Furniture-Stationary'
            />

            <Herosec
                menuText='Safety & Security'
                leftText='
                Our Safety and Security division offers premium industrial safety equipment and advanced security solutions, ensuring a safe, secure working environment, and protecting your operations with reliable, top-tier products tailored to your needs.'
                rightimg={safteysecurity}
                link='/saftey-security'
            />

        </div>
    )
}

export default Index
