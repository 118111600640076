import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Herosec from '../../section/verticals/construction'
import Formcontact from '../../section/contactform/form'

import officefurniture from '../../assets/images/Office_Furniture_893x552.webp'

import Cards from '../../section/cards'

import Stationary from '../../assets/images/stationary_square.jpeg'
import OfficeEquipment from '../../assets/images/officeequipment.jpeg'
import OfficeDeskSeating from '../../assets/images/officedesk_square.jpeg'


import mapimg from '../../assets/images/map.png'

const Index = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);
    const link = '/contact-us'
    return (
        <div>
            <Herosec
                menuText='Office Furniture and Stationary'
                leftText='
                Our Office Furniture & Stationery division provides high-quality office furnishings and essential stationery, meticulously designed to enhance workplace efficiency, comfort, and productivity, ensuring a well-equipped and productive work environment.'
                rightimg={officefurniture}
                link = {link}
            />

            <div className='container my-5'>
                <div className='d-flex flex-wrap gap-2 justify-content-around '>
                    <Cards img={Stationary}  text="Stationary" />
                    <Cards img={OfficeEquipment} text="Office Equipment" />
                    <Cards img={OfficeDeskSeating} text="Office Desk and Seating" />
                </div>
            </div>
            

            <Container className=' mt-4'>
                <span className='form-head'>Get in Touch</span>
            </Container>

            <div>
                <Container className=' mb-5 pb-5' >
                    <Row>
                        <Col sm={12} lg={6} className='my-auto'>
                            <img src={mapimg} alt="map" className='img-fluid ' />
                        </Col>
                        <Col sm={12} md={6} className='form-bg p-4 rounded-4 '>
                            <Formcontact />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Index
