import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Herosec from '../../section/verticals/construction'
import Formcontact from '../../section/contactform/form'
import mapimg from '../../assets/images/map.png'
import residentialmain from '../../assets/images/residential-top_893x552.jpeg'
import Cards from '../../section/cards'
import resi1 from '../../assets/images/resi-1.jpeg'
import resi2 from '../../assets/images/resi-2.jpeg'
import resi3 from '../../assets/images/resi-3.jpeg'

const Index = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Herosec
                menuText='Residential Construction'
                leftText='Build your dream home with our exceptional residential construction services. Specializing in beautiful, durable, custom-designed homes, our skilled team ensures high-quality craftsmanship and attention to detail for a seamless building experience.'
                rightimg={residentialmain}
            />
            <div className='container my-5'>
                <div className='d-flex flex-wrap gap-2 justify-content-around '>
                    <Cards img={resi1} text="Build your dream home with our exceptional residential         construction services." />
                    <Cards img={resi2} text="We specialize in creating beautiful, durable, custom-designed homes." />
                    <Cards img={resi3} text="Trust us to deliver a seamless and stress-free building experience." />
                </div>
            </div>

            <Container className=' mt-4'>
                <span className='form-head'>Get in Touch</span>
            </Container>

            <div>
                <Container className=' mb-5 pb-5' >
                    <Row>
                        <Col sm={12} lg={6} className='my-auto'>
                            <img src={mapimg} alt="map" className='img-fluid ' />
                        </Col>
                        <Col sm={12} md={6} className='form-bg p-4 rounded-4 '>
                            <Formcontact />
                        </Col>
                    </Row>
                </Container>
            </div>

        </div>
    )
}

export default Index
