import React, { useState, useEffect } from 'react'
import { Box, Typography, IconButton, Grid, Stack } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
// import { Card, CardMedia } from '@mui/material';
import aboutUsbg from '../assets/images/aboutus-bg.png';
import qualityserviceheadline from '../assets/images/head line.png'
import happyClient from '../assets/images/client.png'
import service1 from '../assets/images/service-1.png'
import service2 from '../assets/images/service-2.png'
// import kmpg from '../assets/images/KMPG.png'
// import mahindra from '../assets/images/mahindra.png'
// import tata from '../assets/images/TATA.png'
// import exide from '../assets/images/exide.png'
// import force from '../assets/images/force.png'
// import bajaj from '../assets/images/bajaj.png'
// import clients from '../assets/images/clients-removebg-preview.png'

import AboutUs from '../section/landingabout/landingAboutus'
import { Card, Container } from 'react-bootstrap';

import HomeCard from '../section/home-card'

import industrial from '../assets/images/industrial_square.jpeg';
import residential from '../assets/images/residential_square.jpeg';
import aluminiun from '../assets/images/aluminium_square.jpeg';
import fabrication from '../assets/images/fabrication-card.jpeg';
import packaging from '../assets/images/packaging_square.jpeg';
import indirect from '../assets/images/indirect_square.jpeg';
import { Link } from 'react-router-dom';

import machinefoundation from '../assets/images/machinefoundation.jpeg'
import dock from '../assets/images/dockwork.jpeg'
import peb from '../assets/images/peb.jpeg'
import foundationpit from '../assets/images/Foundationpit.jpeg'
import tremixfloor from '../assets/images/trimixflooring.jpeg'

import clientss from '../assets/images/clientss.jpeg'
import cie from '../assets/images/cie.jpeg'
import united from '../assets/images/united.jpeg'
import marelli from '../assets/images/marelli.jpeg'
import igw from "../assets/images/gw.jpeg"
import endurance from '../assets/images/endurance.jpeg'

// import Splashscreen from '../components/splashscreen';

import './home.css'


const Home = () => {
  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   // Set a timer to hide the splash screen after 3 seconds (adjust as needed)
  //   const timer = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 3000);

  //   return () => clearTimeout(timer); // Cleanup the timer on component unmount
  // }, []);

  // if (isLoading) {
  //   return <Splashscreen />;
  // }


  return (
    <>
  
      {/* for desktop */}
      <Box sx={{ display: { xs: 'none', md: 'block' } }} >
        <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={machinefoundation} className="d-block w-100" alt="Machine Foundation" />
              <div className="carousel-caption d-md-block text-start fw-bold text-white carousal-text-bg mb-lg-5">
                <div className="carousel-caption-bg">
                  <h2 className="display-1">Machine Foundation</h2>
                  <p>Expertly constructing machine foundations for diverse industries with precision and care</p>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img src={dock} className="d-block w-100" alt="Dock Work" />
              <div className="carousel-caption d-none d-md-block text-start fw-bold text-white carousal-text-bg">
                <div className="carousel-caption-bg">
                  <h2 className="display-1">Dock Work</h2>
                  <p>Building strong and reliable dock foundations for various industries securely</p>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img src={peb} className="d-block w-100" alt="Pre-Engineered Buildings" />
              <div className="carousel-caption d-none d-md-block text-start fw-bold text-white carousal-text-bg">
                <div className="carousel-caption-bg">
                  <h2 className="display-1">Pre-Engineered Buildings</h2>
                  <p className="fs-5">Expertly constructing robust and reliable pre-engineered buildings for diverse industrial applications</p>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img src={foundationpit} className="d-block w-100" alt="Foundation Pit" />
              <div className="carousel-caption d-none d-md-block text-start fw-bold text-white carousal-text-bg">
                <div className="carousel-caption-bg">
                  <h2 className="display-1">Foundation Pit</h2>
                  <p className="fs-5">Constructing robust foundation pits for heavy machinery stability</p>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img src={tremixfloor} className="d-block w-100" alt="Tremix Flooring" />
              <div className="carousel-caption d-none d-md-block text-start fw-bold text-white carousal-text-bg">
                <div className="carousel-caption-bg">
                  <h2 className="display-1">Tremix Flooring</h2>
                  <p className="fs-5">Constructing high-strength and wear-resistant tremix flooring for industrial environments</p>
                </div>
              </div>
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </Box>


      {/* for mobile */}
      <Box sx={{ display: { xs: 'block', md: 'none' } }} >
        <div id="carouselExampleAutoplayingM" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={machinefoundation} className="d-block w-100" alt="Machine Foundation" />
              <div className="carousel-caption  text-start fw-bold text-white carousal-text-bg ">
                <div className="carousel-caption-bg-m text-wrap">
                  <h2 className="txt-shadow">Machine Foundation</h2>
                  <p>Expertly constructing machine foundations for diverse industries with precision and care</p>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img src={dock} className="d-block w-100" alt="Dock Work" />
              <div className="carousel-caption   text-start fw-bold text-white carousal-text-bg">
                <div className="carousel-caption-bg-m">
                  <h2 className="txt-shadow">Dock Work</h2>
                  <p>Building strong and reliable dock foundations for various industries securely</p>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img src={peb} className="d-block w-100" alt="Dock Work" />
              <div className="carousel-caption   text-start fw-bold text-white carousal-text-bg">
                <div className="carousel-caption-bg-m">
                  <h2 className="txt-shadow">Pre-Engineered Buildings</h2>
                  <p>Expertly constructing robust and reliable pre-engineered buildings for diverse industrial applications</p>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img src={foundationpit} className="d-block w-100" alt="Dock Work" />
              <div className="carousel-caption   text-start fw-bold text-white carousal-text-bg">
                <div className="carousel-caption-bg-m">
                  <h2 className="txt-shadow">Foundation Pit</h2>
                  <p>Constructing robust foundation pits for heavy machinery stability</p>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img src={tremixfloor} className="d-block w-100" alt="Dock Work" />
              <div className="carousel-caption   text-start fw-bold text-white carousal-text-bg">
                <div className="carousel-caption-bg-m">
                  <h2 className="txt-shadow">Tremix Flooring</h2>
                  <p>Constructing high-strength and wear-resistant tremix flooring for industrial environments</p>
                </div>
              </div>
            </div>
            
          
    
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplayingM" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplayingM" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </Box>
      <Box mt={2}  >
        <AboutUs />
      </Box>
      <Container className=' mt-4 text-center text-lg-start'>
        <span className='form-head'>Quality Services</span>
      </Container>

      <Container className='mt-5'>
        <div className='d-flex flex-wrap justify-content-around mb-5'>
          <Link as={Link} to='/industrial-construction' className='mb-4'>
            <HomeCard img={industrial} text='Industrial Construction' />
          </Link>
          <Link as={Link} to='/residential-construction' className='mb-4'>
            <HomeCard img={residential} text='Residential Construction' />
          </Link>
          <Link as={Link} to='/Aluminium-Glass-Work' className='mb-4'>
            <HomeCard img={aluminiun} text='Aluminium & Glass Work' />
          </Link>
        </div>
        <div className='d-flex flex-wrap justify-content-around mb-5'>
          <Link as={Link} to='/packaging' className='mb-4'>
            <HomeCard img={packaging} text='Packaging' />
          </Link>
          <Link as={Link} to='/indirect-material' className='mb-4'>
            <HomeCard img={indirect} text='Indirect Material' />
          </Link>
          <Link as={Link} to='/fabrication' className='mb-4'>
            <HomeCard img={fabrication} text='Fabrication' />
          </Link>
        </div>
      </Container>


      <Container className=' mt-4 mb-5 text-center text-lg-start'>
        <span className='form-head'>Our Clients</span>
      </Container>

      <Container className='mt-5 text-center mb-5 d-flex justify-content-around'>
        <img src={clientss} className='img-fluid' ></img>

      </Container>


      {/* <Box mt={10} mb={10} ml={10} sx={{ display: { xs: 'none', md: 'flex' } }} >
        <Card elevation={0}>
          <CardMedia
            component="img"
            image={happyClient}
            height={100}
            alt="Your Image"
          />
        </Card>
      </Box> */}
      {/* <Box mb={10} mx={10} mt={-5} sx={{ display: { xs: 'none', md: 'flex' } }} >
        <Stack sx={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
          <img src={clients} />
        </Stack>
      </Box> */}
    </>

  )
}

export default Home
