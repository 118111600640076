import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Herosec from '../../section/verticals/construction'
import Formcontact from '../../section/contactform/form'

import ithardwareimg from '../../assets/images/IT_Hardware_893x552.webp'

import Cards from '../../section/cards'

import Camera from '../../assets/images/camera.jpeg'
import MobileAccessories from '../../assets/images/accesories_square.jpeg'
import Laptop from '../../assets/images/laptop_square.jpeg'
import Desktop from '../../assets/images/desktop.jpeg'
import Software from '../../assets/images/software.jpeg'
import TV from '../../assets/images/tv.jpeg'

import mapimg from '../../assets/images/map.png'

const Index = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);
    const link = '/contact-us'
    return (
        <div>
            <Herosec
                menuText='IT Hardware and Appliances'
                leftText='Our IT Hardware and Appliances division delivers advanced technology solutions, offering reliable and efficient IT hardware and appliances to support and enhance your business operations. Trust us for superior IT hardware and appliances.'
                rightimg={ithardwareimg}
                link = {link}
            />

            <div className='container my-5'>
                <div className='d-flex flex-wrap gap-2 justify-content-around '>
                    <Cards img={Camera}  text="Camera" />
                    <Cards img={MobileAccessories} text="Mobile Accessories" />
                    <Cards img={Laptop} text="Laptop" />
                </div>
            </div>
            <div className='container my-5'>
                <div className='d-flex flex-wrap gap-2 justify-content-around '>
                    <Cards img={Desktop}  text="Desktop" />
                    <Cards img={Software} text="Software" />
                    <Cards img={TV} text="TV" />
                </div>
            </div>

            <Container className=' mt-4'>
                <span className='form-head'>Get in Touch</span>
            </Container>

            <div>
                <Container className=' mb-5 pb-5' >
                    <Row>
                        <Col sm={12} lg={6} className='my-auto'>
                            <img src={mapimg} alt="map" className='img-fluid ' />
                        </Col>
                        <Col sm={12} md={6} className='form-bg p-4 rounded-4 '>
                            <Formcontact />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Index
