import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import './form.css'
import sndmsg from '../../assets/images/sendmsg.png'

import emailjs from 'emailjs-com';
// import Alert from 'react-bootstrap/Alert';

import { Snackbar, Alert } from '@mui/material';



const Cform = () => {

    const initialFormData = {
        name: '',
        email: '',
        message: '',
        contactNum: '',
        city: '',
        subject: '',
        message: ''
    };

    const [successopen, setsuccessOpen] = useState(false);
    const [erroropen, seterrorOpen] = useState(false);
    const [msg, setMsg] = useState('');
    const [formData, setFormData] = useState({ ...initialFormData   });

    // const resetForm = () => {
    //     setFormData(initialFormData);
    // };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        console.log(formData);
    
        const serviceID = 'service_3tsz0d2';
        const templateID = 'template_obwjduu';
        const userID = 'SEG2HKwC83iTKZYS-';
    
        let timerId = null;
    
        emailjs.send(serviceID, templateID, formData, userID)
            .then((response) => {
                if(response.status === 200 ){
                    console.log('SUCCESS!', response.status, response.text);
                    setFormData(initialFormData);
                    setMsg('Message sent successfully!');
                    setsuccessOpen(true);
                    seterrorOpen(false);
                }
    
                timerId = setTimeout(() => {
                    setsuccessOpen(false);
                }, 6000); // hide after 3 seconds
            }, (err) => {
                console.error('FAILED...', err);
                setMsg('Failed to send message, please try again.');
                seterrorOpen(true);
                setsuccessOpen(false);
    
                timerId = setTimeout(() => {
                    seterrorOpen(false);
                }, 6000); // hide after 3 seconds
            });
    
        // Clear the timer if the component unmounts
        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        };
    };

    return (
        <div>
            <Snackbar
                open={successopen}
                autoHideDuration={1000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert  severity="success" sx={{ width: '100%' }}>
                    <p><strong>{msg}</strong></p>
                </Alert>
            </Snackbar>
            <Snackbar
                open={erroropen}
                autoHideDuration={1000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert  severity="success" sx={{ width: '100%' }}>
                    <p><strong>{msg}</strong></p>
                </Alert>
            </Snackbar>

            <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridName">
                        {/* <Form.Label>Name</Form.Label> */}
                        <Form.Control type="text" placeholder="Enter Name" className='rounded-5 ' value={formData.name} name="name" onChange={handleChange} required />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                        {/* <Form.Label>Email</Form.Label> */}
                        <Form.Control type="email" placeholder="Enter Email" className='rounded-5 ' value={formData.email} name="email" onChange={handleChange} required />
                    </Form.Group>
                </Row>

                <Form.Group className="mb-3" controlId="formGridAddress1">
                    {/* <Form.Label>Address</Form.Label> */}
                    <Form.Control placeholder="Contact Number" className='rounded-5 'value={formData.contactNum} name="contactNum" onChange={handleChange} required />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGridAddress2">
                    {/* <Form.Label>Address 2</Form.Label> */}
                    <Form.Control placeholder="City" className='rounded-5 'value={formData.city} name="city" onChange={handleChange} required />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGridAddress2">
                    {/* <Form.Label>Address 2</Form.Label> */}
                    <Form.Control placeholder="Subject" className='rounded-5 'value={formData.subject} name="subject" onChange={handleChange} required />
                </Form.Group>

                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    {/* <Form.Label>Example textarea</Form.Label> */}
                    <Form.Control as="textarea" rows={5} placeholder='Message'value={formData.message} name="message" onChange={handleChange} required />
                </Form.Group>

                <div className='d-flex justify-content-center'>
                    {/* <Button variant="primary" type="submit" className='btn-bg '>
                        
                    </Button> */}
                    <Button variant='fill' type='submit' onClick={handleSubmit} className='px-5 text-white fw-bold border hov' style={{ background: 'linear-gradient(160deg, #FFAA85, #B3315F)' }}>Submit</Button>
                </div>
            </Form>
        </div>
    )
}

export default Cform
