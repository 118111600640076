import React, { useEffect } from 'react'
import Careerform from '../../section/careerform/career'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, Typography, IconButton, Grid, Stack, Divider } from '@mui/material';
import clients from '../../assets/images/clientss.jpeg'

const Index = () => {

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <div>
                <Container className='text-center mt-5'>
                    <span className='contact'>Careers</span>
                </Container>
                <Container className='text-center mt-4'>
                    <span className='contact-head'>Get in touch with us for<br />
                        Job Opportunities</span>
                </Container>
                <Container className='text-center mt-1'>
                    <span className='contact-subhead'>If you need help or have a question, we’re here for you</span>
                </Container>
                <Container className=' mt-4 mb-5 text-center text-lg-start'>
                    <span className='form-head'>Job Openings at Auto-Tech</span>
                </Container>

                <Container className=''>
                    <Row className='' >
                        <Col sm={12} lg={5} className='  border rounded-4'>
                            {/* <img src={mapimg} alt="map" className='img-fluid ' /> */}
                            <div className=' career-left-bg rounded-4 d-flex fw-bold'>
                                Designation: Fabrication/Welder/Fitter<br />
                                Salary: As per industry norms<br />
                                Experience Required: 1 to 3 Years<br />
                                Vacancy : 10<br />
                                <br />
                                Designation: Fabrication Supervisor<br />
                                Salary: As per industry norms<br />
                                Experience Required: 3 to 5 Years<br />
                                Vacancy : 02<br />
                                <br />
                                Designation: Civil Supervisor<br />
                                Salary: As per industry norms<br />
                                Experience Required: 1 to 3 Years<br />
                                Vacancy : 02<br />
                                <br />
                                Designation: Diploma Purchase Engineer<br />
                                Salary: As per industry norms<br />
                                Experience Required: 2 to 4 Years<br />
                                Vacancy : 01<br />
                                <br />
                                Designation: Accountant<br />
                                Salary: As per industry norms<br />
                                Experience Required: 5 to 8 Years<br />
                                Vacancy : 01<br />
                            </div>
                        </Col>
                        <Col md={1} className=''>

                        </Col>
                        <Col sm={12} md={6} className='form-bg p-4 mb-5 rounded-4 '>
                            <div>
                                <Careerform />
                            </div>
                        </Col>
                    </Row>
                </Container>

                {/* <Container className=' mt-4 mb-5'>
                <span className='form-head'>Our Client</span>
            </Container> */}
                <Box mb={10} mx={10} mt={5} sx={{ display: { xs: 'none', md: 'flex' } }} >
                    <Stack sx={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                        <Stack direction="row" sx={{ display: 'flex', justifyContent: 'space-around' }}>
                            {/* <img src={kmpg}/>
            <img src={bajaj}/>
            <img src={force}/>
            <img src={exide}/> */}
                            <Container className='mt-5 text-center mb-5'>
                                {/* <img src={clients} className='img-fluid' ></img> */}
                            </Container>
                        </Stack>
                    </Stack>
                </Box>
            </div>
        </div>
    )
}

export default Index
