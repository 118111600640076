import React, { useEffect } from 'react'
import Fabrication from '../../section/verticals/construction'
import pebImg from '../../assets/images/PEB_893x552.jpg'
import trolley from '../../assets/images/trolley_893x552.jpeg'
import rollingshutter from '../../assets/images/rollingshutter_893x552.jpeg'
import repairingwork from '../../assets/images/repairingwork_893x552.jpeg'

const Index = () => {
    const link = '/contact-us'
    useEffect(() => {
      // Scroll to the top of the page when the component mounts
      window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Fabrication 
        menuText='PEB'
        leftText='Optimize construction with our durable, custom-designed PEB solutions. Enjoy quick    assembly, reduced costs, and versatile applications for industrial, commercial, and agricultural use. Trust our high-quality materials and precise engineering.'
        rightimg={pebImg}
        link={link}
      />
      <Fabrication 
        menuText='Repairing Works'
        leftText='Expert Fabrication Repairing: Skilled in metal component repair and reinforcement for durability and safety. We offer structural repairs, welding, and custom fabrication for various industries, ensuring precise and reliable solutions.'
        rightimg={repairingwork}
        link={link}
      />
      <Fabrication 
        menuText='New Trolley Making'
        leftText='Enhance efficiency with our durable, custom trolleys. Tailored to your needs, they ensure optimal performance with high-quality materials and precise engineering for industrial and commercial applications. Trust our innovative, reliable designs.'
        rightimg={trolley}
        link={link}
      />
      <Fabrication 
        menuText='Rolling Shutter Work'
        leftText='Secure your property with our durable, easy-to-use rolling shutters. Ideal for commercial, industrial, and residential use, we offer custom solutions with precise installation and reliable materials for long-lasting protection.'
        rightimg={rollingshutter}
        link={link}
      />
    </div>
  )
}

export default Index
