import React, { useEffect } from 'react'
import Civiconstruction from '../../section/verticals/construction'

import machinefoundation from '../../assets/images/Machine-foundation_893x552.webp'
import tremix from '../../assets/images/trmix_893x552.jpg'
import dockwork from '../../assets/images/dockwork_893x552.jpg'
import foundationpit from '../../assets/images/Foundation_pit_893x552.webp'
import industrialConstruction from '../../assets/images/Industrial_Construction_893x552.webp'

const Index = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);
    const links = '/contact-us'
    return (
        <div>
            <Civiconstruction
                menuText='Machine Foundation'
                leftText="
                        Ensure stable and efficient machinery with our expert foundation construction. We design and build robust foundations tailored to your equipment, using the latest techniques and materials for precision and durability."
                rightimg={machinefoundation}
                link={links}
            />
            <Civiconstruction
                menuText='Tremix Flooring'
                leftText="
                        Upgrade industrial floors with premium Tremix services. We specialize in durable, dust-free surfaces using advanced technology for fast installation. Enhance safety and efficiency with our reliable flooring solutions."
                rightimg={tremix}
                link={links}
            />
            <Civiconstruction
                menuText='Dock Work'
                leftText="
                        Enhance facility efficiency with expert dock construction. We specialize in robust docks tailored to your needs, ensuring seamless loading processes. Trust our skilled team for reliable solutions supporting your logistics."
                rightimg={dockwork}
                link={links}
            />
            <Civiconstruction
                menuText='Foundation Pit'
                leftText="
                        Strengthen structures with specialized pit foundation construction. We design and build durable foundations tailored to unique requirements, ensuring stability. Trust our experienced team for precise results supporting your success."
                rightimg={foundationpit}
                link={links}
            />
            <Civiconstruction
                menuText='Industrial Building Works'
                leftText="        
                        Transform industrial spaces with comprehensive building works. Specializing in robust, efficient facilities tailored to your needs, our experienced team ensures high-quality workmanship, compliance, and reliability to enhance operations."
                rightimg={industrialConstruction}
                link={links}
            />
        </div>
    )
}

export default Index
