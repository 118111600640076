import React, {useEffect} from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Herosec from '../../section/verticals/construction'
import Formcontact from '../../section/contactform/form'

import hydraulicimg from '../../assets/images/Hydraulics_and_Pneumatics_893x552.webp'

import Cards from '../../section/cards'

import GearPump from '../../assets/images/gearpump.jpeg'
import Valves from '../../assets/images/valves_hydraulic_square.jpeg'
import Motors from '../../assets/images/hydraulicmotor.jpeg'
import Cylinders from '../../assets/images/cylinder.jpeg'
import HoseHoseFitting from '../../assets/images/hydraulichosesfitting.jpeg'
import Flanging from '../../assets/images/flanging.jpeg'

import mapimg from '../../assets/images/map.png'

const Index = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);
    const link = '/contact-us'
    return (
        <div>
            <Herosec
                menuText='Hydraulics and Pneumatics'
                leftText='Our Hydraulics & Pneumatics division delivers high-performance industrial solutions with reliable and efficient components, optimizing your machinery and processes for enhanced productivity and operational efficiency. Trust us for superior hydraulic and pneumatic systems.'
                rightimg={hydraulicimg}
                link = {link}
            />

            <div className='container my-5'>
                <div className='d-flex flex-wrap gap-2 justify-content-around '>
                    <Cards img={GearPump}  text="Gear Pump" />
                    <Cards img={Valves} text="Valves" />
                    <Cards img={Motors} text="Motors" />
                </div>
            </div>
            <div className='container my-5'>
                <div className='d-flex flex-wrap gap-2 justify-content-around '>
                    <Cards img={Cylinders}  text="Cylinders" />
                    <Cards img={HoseHoseFitting} text="Hose & Hose Fitting" />
                    <Cards img={Flanging} text="Flanges" />
                </div>
            </div>

            <Container className=' mt-4'>
                <span className='form-head'>Get in Touch</span>
            </Container>

            <div>
                <Container className=' mb-5 pb-5' >
                    <Row>
                        <Col sm={12} lg={6} className='my-auto'>
                            <img src={mapimg} alt="map" className='img-fluid ' />
                        </Col>
                        <Col sm={12} md={6} className='form-bg p-4 rounded-4 '>
                            <Formcontact />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Index
