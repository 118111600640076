import { Box, Grid, Typography, IconButton, Stack, Container } from '@mui/material'
import React from 'react'
import footerbg from '../assets/images/Rectangle28.png'
import mainLogo from '../assets/images/mainlogo.png';
import { Link } from 'react-router-dom';

const footer = () => {
  return (
    <div>
      <Box sx={{ backgroundImage: `url(${footerbg})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', display: { xs: 'none', md: 'flex' }, width: '100%', height: '300px' }}>
        <Grid pt={2} container spacing={0} sx={{ color: 'white', fontFamily: '' }}>
          <Grid item md={3} sx={{ height: '100%' }} >
            <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center', alignContent: 'center', marginTop: '60px' }}>
              <IconButton>
                <img src={mainLogo} alt="main logo" width={200} />
              </IconButton>
            </Box>
          </Grid>
          <Grid item md={2} className='align-content-center'>
            <Stack spacing={2}>
              <Typography fontWeight={'bold'}>Auto-Tech Industries</Typography>
              <Link to="/about-us" style={{ textDecoration: 'none', color: 'white' }}><Typography>About Us</Typography></Link>
              <Link to="/career" style={{ textDecoration: 'none', color: 'white' }}><Typography>Careers</Typography></Link>
              {/* <Typography>FAQ</Typography> */}
              <Typography>Teams</Typography>
              <Link to="/contact-us" style={{ textDecoration: 'none', color: 'white' }}><Typography>Contact Us</Typography>
              </Link>
            </Stack>
          </Grid>

          <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Stack spacing={1} sx={{ mx: 'auto' }}>
              <Typography fontWeight={'bold'} >Contact Us</Typography>
              <Typography>Plot no. 4/410, Lane no. 06, sharad nagar,</Typography>
              <Typography>spine road, chikhali, pune-62.</Typography>
              <Typography>Phone : 7757080968, 9975061844</Typography>
              <Typography>Email : info@auto-tech.co.in</Typography>
            </Stack>

          </Grid>
        </Grid>
      </Box>
      


      <Box sx={{ backgroundImage: `url(${footerbg})`, backgroundSize: '', backgroundRepeat: '', display: { xs: 'block', md: 'none' } }}>
        <div>
          <div className='text-center '>
            <IconButton >
              <img src={mainLogo} alt="main logo" width={200} />
            </IconButton>
          </div>
          <div className='text-center mt-4'>
            <Stack spacing={1}>
              <Typography fontWeight={'bold'} fontSize={20} style={{ textDecoration: 'none', color: 'white' }}>Auto-Tech Industries</Typography>
              <Link to="/about-us" style={{ textDecoration: 'none', color: 'white' }}><Typography>About Us</Typography></Link>
              <Link to="/career" style={{ textDecoration: 'none', color: 'white' }}><Typography>Careers</Typography></Link>
              {/* <Typography>FAQ</Typography> */}
              {/* <Typography>Teams</Typography> */}
              <Link to="/contact-us" style={{ textDecoration: 'none', color: 'white' }}><Typography>Contact Us</Typography>
              </Link>
            </Stack>
          </div>
          <div className='text-white mt-3 mb-1 text-wrap text-center'>
            <Stack spacing={0.5} sx={{ mx: 'auto' }}>
              <Typography fontWeight={'bold'} fontSize={20} >Contact Us</Typography>
              <Typography>Plot no. 4/410, Lane no. 06, sharad nagar,</Typography>
              <Typography>spine road, chikhali, pune-62.</Typography>
              <Typography>Phone : 7757080968, 9975061844</Typography>
              <Typography>Email : info@auto-tech.co.in</Typography>
            </Stack>
          </div>
        </div>
      </Box>
      <Container>
        <Stack spacing={1} sx={{ mx: 'auto', marginTop: '', color: 'black', justifyContent:'center', alignItems:'center' }}>
          <Typography textAlign="center" 
          sx={{ fontSize: '0.875rem' }}   >Designed and Developed By DD Corporation  Email-Id: office@ddcorporation.in</Typography>
        </Stack>
      </Container>
    </div>
  )
}

export default footer
