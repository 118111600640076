import { width } from '@mui/system'
import React from 'react'
import { Card } from 'react-bootstrap'

import './index.css'

const index = (props) => {
    return (
        <div>

            <Card className="shadow  text-white">
                <Card.Img src={props.img} alt="Card image" style={{ width: '18rem' }} />
                <Card.ImgOverlay className='d-flex flex-column justify-content-end  '>
                    
                    <Card.Text className='ps-2 card-text '>
                        {props.text}
                    </Card.Text>
                    {/* <Card.Text>Last updated 3 mins ago</Card.Text> */}
                </Card.ImgOverlay>
            </Card>
        </div>
    )
}

export default index
