import React, { useEffect } from 'react'
import './contact.css'
import { Col, Container, Row } from 'react-bootstrap'
import Formcontact from '../../section/contactform/form'

import mapimg from '../../assets/images/map.png'

import kmpg from '../../assets/images/KMPG.png'
import phoneicon from '../../assets/images/phn.png'
import arrowicon from '../../assets/images/arrow.png'

import { Card, CardMedia } from '@mui/material';

import { Box, Typography, IconButton, Grid, Stack } from '@mui/material';

import clients from '../../assets/images/clientss.jpeg'

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix for default icon issue
// delete L.Icon.Default.prototype._getIconUrl;
// L.Icon.Default.mergeOptions({
//     iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
//     iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
//     shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
// });

const center = [51.505, -0.09];

const Contact = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Container className='text-center mt-5'>
                <span className='contact'>Contact Us</span>
            </Container>
            <Container className='text-center mt-4'>
                <span className='contact-head'>Get in touch with us for<br />
                    more information</span>
            </Container>
            <Container className='text-center mt-1'>
                <span className='contact-subhead'>If you need help or have a question, we’re here for you</span>
            </Container>



            <Container className=' mt-4'>
                <span className='form-head'>Get in Touch</span>
            </Container>

            <Container className=''>
                <Row>
                    <Col sm={12} lg={6} className='my-auto'>
                        {/* <img src={mapimg} alt="map" className='img-fluid ' /> */}
                        <MapContainer center={center} zoom={13} style={{ height: "100%", width: "100%" }}>
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <Marker position={center}>
                                <Popup>
                                    A pretty CSS3 popup. <br /> Easily customizable.
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </Col>
                    <Col sm={12} md={6} className='form-bg p-4 rounded-4 mb-5'>
                        <Formcontact />
                    </Col>
                </Row>
            </Container>

            {/* <Container className=' mt-4 mb-5'>
                <span className='form-head'>Our Client</span>
            </Container> */}
            <Box mb={10} mx={10} mt={5} sx={{ display: { xs: 'none', md: 'flex' } }} >
                <Stack sx={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                    <Stack direction="row" sx={{ display: 'flex', justifyContent: 'space-around' }}>
                        {/* <img src={kmpg}/>
            <img src={bajaj}/>
            <img src={force}/>
            <img src={exide}/> */}
                        <Container className='mt-5 text-center mb-5'>
                            {/* <img src={clients} className='img-fluid' ></img> */}
                        </Container>
                    </Stack>
                </Stack>
            </Box>
        </div>
    )
}

export default Contact
