import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Herosec from '../../section/verticals/construction'
import Formcontact from '../../section/contactform/form'

import abrasiveimg from '../../assets/images/Abrasives_and_Tools_893x552.webp'

import Cards from '../../section/cards'

import Fasteners from '../../assets/images/fastner_450x450.jpeg'
import MechanicalPowerTools from '../../assets/images/mechanicalpoertools_450x450.jpeg'
import MachineHandTools from '../../assets/images/machinehandtools_450x450.jpeg'
import ElectricalToolsMachines from '../../assets/images/electricaltools_450x450.jpeg'
import Abrasives from '../../assets/images/abrasive_450x450.jpeg'
import SparePartsforTools from '../../assets/images/sparepartstools_450x450.jpeg'

import mapimg from '../../assets/images/map.png'

const Index = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);
    const link = '/contact-us'
    return (
        <div>
            <Herosec
                menuText='Abrasive and Tools'
                leftText='Our Abrasives and Tools division offers top-quality, durable, and precise industrial tools and materials, enhancing productivity and performance. Trust us for reliable cutting, grinding, and polishing solutions tailored to your needs.'
                rightimg={abrasiveimg}
                link = {link}
            />

            <div className='container my-5'>
                <div className='d-flex flex-wrap gap-2 justify-content-around '>
                    <Cards img={Fasteners}  text="Fasteners" />
                    <Cards img={MechanicalPowerTools} text="Mechanical Power Tools" />
                    <Cards img={MachineHandTools} text="Machine Hand Tools" />
                </div>
            </div>
            <div className='container my-5'>
                <div className='d-flex flex-wrap gap-2 justify-content-around '>
                    <Cards img={ElectricalToolsMachines}  text="Electrical Tools & Machines" />
                    <Cards img={Abrasives} text="Abrasives" />
                    <Cards img={SparePartsforTools} text="Spare Parts for Tools" />
                </div>
            </div>

            <Container className=' mt-4'>
                <span className='form-head'>Get in Touch</span>
            </Container>

            <div>
                <Container className=' mb-5 pb-5' >
                    <Row>
                        <Col sm={12} lg={6} className='my-auto'>
                            <img src={mapimg} alt="map" className='img-fluid ' />
                        </Col>
                        <Col sm={12} md={6} className='form-bg p-4 rounded-4 '>
                            <Formcontact />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Index
