import React from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Row, Stack } from 'react-bootstrap'
import './construction.css'

import dotbg from '../../assets/images/dots.png'
import { Box, Container } from '@mui/material'
import { display } from '@mui/system'
import { Link } from 'react-router-dom'

const construction = props => {
    return (
        <div>
            <Container>
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

                    <div className='container mt-5  container-bg  mb-5 position-relative rounded-5 '>
                        <div className='row text-center gap-0  '>
                            <div className=' col-12 col-lg-5  left-item-new '>
                                <div className='d-flex flex-column mt-lg-4  '>
                                    <span className='menu-text mt-lg-5 text-start ms-lg-5  '>{props.menuText}</span>
                                    <div className='left-bg ms-lg-5 pb-lg-4 ps-lg-4  mb-lg-2 text-end py-lg-5 rounded-3 '>
                                        <p className='left-head-text pt-lg-4 me-lg-4 text-end '>{props.leftText}</p>
                                        <p className='left-head-text-2 me-lg-4 text-start '>{props.leftTextbottom}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-7  pe-5'>
                                <div className=' me-5 mt-5'>
                                    <img src={props.rightimg} className='img-fluid  me-lg-5 rounded-3' ></img>
                                </div>
                                <Link to={props.link}>
                                    <Button className='btn btn-bg my-4 float-end fw-bold px-3 border '>Know More</Button>
                                </Link>
                            </div>
                        </div>
                        <div>
                            <img src={dotbg} className='dot-img z-n1 ' width='30%' />
                        </div>
                    </div>
                </Box>

                {/* for mobile */}
                <Box sx={{ display: { xs: 'block', md: 'none' } }}>

                    <div className='container mt-5  container-bg  mb-5 position-relative rounded-5 '>
                        <div className='row text-center gap-0 '>
                            <div className=' col-12 col-lg-5  '>
                                <div className='d-flex flex-column mt-lg-4  '>
                                    <span className='menu-text my-4 text-center  '>{props.menuText}</span>
                                    <div className='  rounded-3 '>
                                        <p className='left-head-text '>{props.leftText}</p>
                                        {/* <p className='left-head-text-2   '>{props.leftTextbottom}</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-7'>
                                <div className=' text-center mt-2'>
                                    <img src={props.rightimg} className='img-fluid  rounded-3' ></img>
                                </div>
                                <Link to={props.link}>
                                    <Button className='btn btn-bg my-4 float-end fw-bold px-3 border '>Know More</Button>
                                </Link>
                            </div>
                        </div>
                        <div>
                            <img src={dotbg} className='dot-img z-n1 ' width='30%' />
                        </div>
                    </div>
                </Box>
            </Container>
        </div>
    )
}

construction.propTypes = {

}

export default construction
