import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Herosec from '../../section/verticals/construction'
import Formcontact from '../../section/contactform/form'

import electricalimg from '../../assets/images/Electrical_Items_893x552.webp'

import Cards from '../../section/cards'

import Cables from '../../assets/images/cables_square.jpeg'
import Motors from '../../assets/images/motors_square.jpeg'
import CircuitBreakers from '../../assets/images/circuitbreaker_450x450.jpeg'
import MetersRelaysPanels from '../../assets/images/meter_square.jpeg'
import LargeAppliances from '../../assets/images/largeapliance_square.jpeg'
import LEDLightsFittings from '../../assets/images/led_square.jpeg'

import mapimg from '../../assets/images/map.png'

const Index = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Herosec
                menuText='Electrical Items'
                leftText='Our Electrical Items division supplies premium industrial electrical components, ensuring safety, efficiency, and reliability to power your operations with cutting-edge technology and competitive pricing.'
                rightimg={electricalimg}
                link='/contact-us'
            />

            <div className='container my-5'>
                <div className='d-flex flex-wrap gap-2 justify-content-around '>
                    <Cards img={Cables}  text="Cables" />
                    <Cards img={Motors} text="Motors" />
                    <Cards img={CircuitBreakers} text="Circuit Breakers" />
                </div>
            </div>
            <div className='container my-5'>
                <div className='d-flex flex-wrap gap-2 justify-content-around '>
                    <Cards img={MetersRelaysPanels}  text="Meters/Relays/Panels" />
                    <Cards img={LargeAppliances} text="Large Appliances" />
                    <Cards img={LEDLightsFittings} text="LED Lights/ Fittings" />
                </div>
            </div>

            <Container className=' mt-4'>
                <span className='form-head'>Get in Touch</span>
            </Container>

            <div>
                <Container className=' mb-5 pb-5' >
                    <Row>
                        <Col sm={12} lg={6} className='my-auto'>
                            <img src={mapimg} alt="map" className='img-fluid ' />
                        </Col>
                        <Col sm={12} md={6} className='form-bg p-4 rounded-4 '>
                            <Formcontact />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Index
