import React from 'react'

const about = () => {
  return (
    <div>
      about
    </div>
  )
}

export default about
