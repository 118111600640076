import React, { useEffect } from 'react'
import { Col, Container, Row, ListGroup, Card } from 'react-bootstrap'
import './index.css'
import iso from '../../assets/images/ISO CERTIFICATE.jpg'

const Index = () => {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
}, []);
  return (
    <div className='text-white'>

      <Container className='container-bg p-3 mt-3 text-white hov'>
        <Container className=' mt-1 text-center'>
          <span className='quality-head text-white '>Quality Assurance</span>
        </Container>
        <p className='text-start '>
          At Auto-Tech Industries, we prioritize quality in everything we do. Our commitment to quality extends across all aspects of our operations, from civil constructions work to packaging solutions, aluminum and glass work, and fabrication work. We understand that quality is paramount to customer satisfaction and business success
        </p>
      </Container>
      <Container className=' mt-3 container-bg text-center mt-5'>
        <span className='quality-head text-white'>Quality Policy</span>
      </Container>

      <Container className=' mt-3 container-bg text-center hov'>
        <h4>Customer Satisfaction</h4>
        <p>We are dedicated to meeting and exceeding the expectations of our clients by delivering high-quality products and services that fulfill their needs.</p>
      </Container>
      <Container className=' mt-3 container-bg text-center hov'>
        <h4>Continuous Improvement</h4>
        <p>We continuously strive to enhance our processes, systems, and services to ensure they meet or exceed industry standards and best practices.</p>
      </Container>
      <Container className=' mt-3 container-bg text-center hov'>
        <h4>Compliance</h4>
        <p>We adhere to all relevant regulations and standards, ensuring that our products and services consistently meet legal and regulatory requirements.</p>
      </Container>
      <Container className=' mt-3 container-bg text-center hov'>
        <h4>Employee Involvement</h4>
        <p>We encourage the active participation of our employees in quality improvement initiatives, fostering a culture of accountability and excellence.</p>
      </Container>
      <Container className=' mt-3 container-bg text-center hov'>
        <h4>Supplier Relationships</h4>
        <p>We collaborate closely with our suppliers to ensure the quality of materials and components used in our projects and products.</p>
      </Container>
      <Container className=' mt-3 container-bg text-center hov'>
        <h4>Environmental Responsibility</h4>
        <p>We are committed to minimizing the environmental impact of our operations by implementing sustainable practices and technologies wherever possible.</p>
      </Container>



      <Container className='container-bg  my-2 text-black'>
        <Row className=' my-5 aboutus py-3 rounded-4' >

          <Col sm={12} md={6} className='my-auto '>
            <div className='text-white text-end'>
              <h4 className='head-text'>ISO 9001:2015 Certification</h4>
              <p>We are proud to be ISO 9001:2015 certified for our Quality Management Systems. This certification reflects our dedication to maintaining the highest standards of quality across all aspects of our business. By adhering to the rigorous requirements of ISO 9001:2015, we demonstrate our commitment to continuous improvement and customer satisfaction.</p>
            
            </div>
          </Col>
          <Col sm={12} md={6} className=' text-center text-lg-center my-auto'>
            <img src={iso} alt="logo" width={400} className='img-fluid rounded-4 hov' />
            
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Index
